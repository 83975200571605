* {
  overflow-x: none !important;
}
.bg-gray {
  background-color: #c1c1c1 !important;
}

.table {
  --bs-table-striped-bg: #f6f9fc;
}
.table th {
  padding: 0.25rem;
  text-align: center;
  text-wrap: nowrap;
  vertical-align: middle;
}
